export const toReduceACS = ({
  evseUri,
  socket,
  power,
  uri,
}) => ({
  evseUri,
  socket,
  power,
  uri,
});