import { SOCKET_STATUS } from '../../../../constants/charger';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration)

const LOCAL_STORAGE_RT_VIEW = 'b-rt-view';

export const setLocalStorageView = val => {
  localStorage.setItem(LOCAL_STORAGE_RT_VIEW, JSON.stringify(val));
};

export const getLocalStorageView = () => {
  const data = localStorage.getItem(LOCAL_STORAGE_RT_VIEW);
  return JSON.parse(data);
};

export const showCardBodyInfo = socketsStatus => {
  return [
    SOCKET_STATUS.FINISHING,
    SOCKET_STATUS.FINISHED,
    SOCKET_STATUS.SUSPENDEDEV,
    SOCKET_STATUS.SUSPENDEDEVSE,
    SOCKET_STATUS.CHARGING,
  ].includes(socketsStatus);
};

export const statusIsSuspended = socketsStatus => {
  return [
    SOCKET_STATUS.SUSPENDEDEV,
    SOCKET_STATUS.SUSPENDEDEVSE,
  ].includes(socketsStatus);
};

const getDateFormat = departureTime => {
  const isToday = dayjs(departureTime).isSame(dayjs(), 'day');
  const format = isToday ? 'HH:mm' : 'DD-MMM HH:mm';
  return format;
};

export const formatDepartureTime = (departureTime, asapLiteral) => {
  if (!departureTime) return '-';
  // if less than 30 minutes, display asap
  const duration = dayjs
    .duration(dayjs(departureTime).diff(dayjs()))
    ?.asMinutes();
  if (duration < 30) return asapLiteral;
  return dayjs(departureTime)
    ?.local()
    ?.format(getDateFormat(departureTime));
};