import { DEFAULT_BIA_DOMAIN } from '@iso/constants';
import { SocketsTypes } from '@iso/constants/facility';

export const ENTITIES = {
  FACILITY: 'facility',
  OWNER: 'owner',
  ADDRESS: 'address',
  EVSE: 'evse',
  EVSEMODEL: 'evsemodel',
  SOCKET: 'socket',
  IDTAG: 'idtag',
  CHARGINGSESSION: 'chargingsession',
  EV: 'ev',
  EVMODEL: 'evmodel',
  FLEET: 'fleet',
  RESERVATION: 'reservation',
  CONFIGRULE: 'configrule',
  DEFAULTCONFIGRULE: 'defaultconfig',
  CABINET: 'cabinet',
};

const getIdFromUri = (s, entity) => {
  if (!s.includes(entity)) {
    return null;
  }
  const tokens = s.split(':');
  if (tokens.length != 3) {
    return null;
  }
  return tokens[2];
};

export const getIdFromFacilityUri = s =>
  Number(getIdFromUri(s, ENTITIES.FACILITY));
export const getIdFromOwnerUri = s => Number(getIdFromUri(s, ENTITIES.OWNER));
export const getIdFromAddressUri = s =>
  Number(getIdFromUri(s, ENTITIES.ADDRESS));
export const getIdFromEvseUri = s => Number(getIdFromUri(s, ENTITIES.EVSE));
export const getIdFromEvseModelUri = s =>
  Number(getIdFromUri(s, ENTITIES.EVSEMODEL));
export const getIdFromSocketUri = s => Number(getIdFromUri(s, ENTITIES.SOCKET));
export const getIdFromIdTagUri = s => Number(getIdFromUri(s, ENTITIES.IDTAG));
export const getIdFromChargingSessionUri = s =>
  getIdFromUri(s, ENTITIES.CHARGINGSESSION);
export const getIdStringFromIdTagUri = s => getIdFromUri(s, ENTITIES.IDTAG);
export const getIdFromEvUri = s => Number(getIdFromUri(s, ENTITIES.EV));
export const getIdFromEvModelUri = s =>
  Number(getIdFromUri(s, ENTITIES.EVMODEL));
export const getIdFromFleetUri = s => Number(getIdFromUri(s, ENTITIES.FLEET));
export const getIdFromConfigRuleUri = s => Number(getIdFromUri(s, ENTITIES.CONFIGRULE));
export const getIdFromDefaultConfigRuleUri = s => Number(getIdFromUri(s, ENTITIES.DEFAULTCONFIGRULE));

export const checkIfPathIsWithOldId = (param, entity) => {
  const entitiesArr = Object.values(ENTITIES);
  const el = entitiesArr.find(a => param.includes(a));

  if (el) return param;
  const DOMAIN = DEFAULT_BIA_DOMAIN;
  return `${DOMAIN}:${entity}:${param}`;
};

export const socketTypeExists = (socketType) => Object.values(SocketsTypes).find(el => el.value === socketType);