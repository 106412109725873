import { SOCKET_STATUS } from "@iso/constants/charger";
import { ENTITIES } from "@iso/lib/helpers/facilities";
import { isSocketAvalible, isSocketCharging, isSocketFaulted, isSocketFinishing, isSocketOffline } from "../../RealTimePage/utils/chargingSession";
import { CHARGER_TYPE_ALL } from "../../RealTimePage/constants";
import { toReduceACS } from "./mapper";
import { ReactComponent as CabinetIcon } from '@iso/assets/images/icon/Cabinet.svg';
import { ReactComponent as SocketIcon } from '@iso/assets/images/icon/Socket.svg';
import { ReactComponent as EvseIcon } from '@iso/assets/images/icon/Evse.svg';
import { ReactComponent as FacilityIcon } from '@iso/assets/images/icon/Facility.svg';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const LOCAL_STORAGE_TOPOLOGY_PRESETS = 'b-rt-topology';
const NO_VALUE = '-';

export const setLocalStorageTopologyPresets = obj => {
  localStorage.setItem(LOCAL_STORAGE_TOPOLOGY_PRESETS, JSON.stringify(obj));
};

export const getLocalStorageTopologyPresets = () => {
  const data = localStorage.getItem(LOCAL_STORAGE_TOPOLOGY_PRESETS);
  return JSON.parse(data);
};

export const getTotalPower = (chargingSessions) => {
  const totalPower = chargingSessions?.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.power, 0
  );
  return totalPower;
};

export const getPower = (nodeId, data) => {
  const node = data?.nodes?.find(el => el.id === nodeId);
  if (node?.data?.activeChargingSessions) {
    const totalPower = getTotalPower(node?.data?.activeChargingSessions);
    return totalPower;
  } if (node?.data?.activeChargingSession) {
    return node?.data?.activeChargingSession?.power;
  } return 0;
};

export const getBatteryLevel = (maxPower, chargingSessions) => {
  if (!maxPower || !chargingSessions) return 0;
  const totalPower = getTotalPower(chargingSessions);
  const level = (totalPower*100/maxPower)?.toFixed(0);
  return Number(level);
};

export const getBatteryLevelSensor = (maxPower, readingPower) => {
  if (!maxPower || !readingPower) return 0;
  const level = (readingPower*100/maxPower)?.toFixed(0);
  return Number(level);
};

export const getEnergyInKwh = activeChargingSession => {
  if (activeChargingSession) {
    return Number(activeChargingSession.totalEnergyKwh?.toFixed(1));
  }
};

export const getTag = activeChargingSession => {
  if (activeChargingSession) {
    return activeChargingSession.userTag;
  }
  return NO_VALUE;
};

export const getDesiredSoc = activeChargingSession => {
  if (activeChargingSession?.desiredSoc) {
    return Number((activeChargingSession.desiredSoc * 100)?.toFixed(1));
  }
  return 100;
};

export const getStartedCharging = (activeChargingSession, locale) => {
  if (activeChargingSession?.connectionTime) {
    return dayjs(activeChargingSession.connectionTime)
      .locale(locale)
      .fromNow();
  }
  return NO_VALUE;
};

export const getDepartureTime = activeChargingSession => {
  if (!activeChargingSession?.estimatedSessionDuration) return;
  return dayjs(activeChargingSession.connectionTime).add(
    activeChargingSession.estimatedSessionDuration,
    'seconds'
  );
};

export const getVehicleInfo = (activeChargingSession, vehicles) => {
  if (activeChargingSession?.evUri) {
    const vehicle = vehicles?.find(v => v.uri == activeChargingSession.evUri);
    return vehicle ? 
      { name: vehicle.name, type: vehicle.evType } : 
      { name: NO_VALUE };
  }
  return { name: NO_VALUE };
};

export const handleCreateSocketsArray = evsesArray => {
  const socketsArray = [];
  evsesArray.forEach(charger => {
    charger.sockets?.forEach(socket => {
      socketsArray.push({
        ...socket,
        charger,
      });
    });
  });

  return socketsArray;
};

export const handleFormatObjWithCabinets = (cabinets, facility) => {
    const nodes = [{
      id: '0',
      type: ENTITIES.FACILITY,
      data: {
        uri: facility?.uri,
        power: facility?.maxAvailablePower,
      },
    }];
    const edges = [];
    let lastIndex = 1;

    const cabinetsWithNoParent = cabinets?.filter(el => !el.parentUri);
    const cabinetsWithParent = cabinets?.filter(el => !!el.parentUri);

    cabinetsWithNoParent?.forEach((cabinet, i) => {
      const idx = i + 1;
      nodes.push({
        id: String(idx),
        type: ENTITIES.CABINET,
        data: cabinet,
      });
      edges.push({
        source: '0',
        target: String(idx),
      });
      lastIndex = idx;
    });

    cabinetsWithParent?.forEach(cabinet => {
      const getSource = nodes.find(el => el.data?.uri === cabinet.parentUri)?.id;
      if (!getSource) return;
      const idx = String(lastIndex + 1);
      nodes.push({
        id: idx,
        type: ENTITIES.CABINET,
        data: cabinet,
      });
      edges.push({
        source: String(getSource),
        target: idx,
      });
      lastIndex += 1;
    });

    return { nodes, edges };
};

export const handleFormatObjWithEvses = (evses, data) => {
    const nodes = JSON.parse(JSON.stringify(data?.nodes));
    const edges = JSON.parse(JSON.stringify(data?.edges));
    let lastIndex = nodes.length ? Math.max(...nodes.map(obj => Number(obj.id))) : 0;

    const evsesWithNoParent = evses?.filter(el => !el.cabinetUri);
    const evsesWithParent = evses?.filter(el => !!el.cabinetUri);

    evsesWithNoParent?.forEach(evse => {
      lastIndex += 1;
      nodes.push({
        id: String(lastIndex),
        type: ENTITIES.EVSE,
        data: evse,
      });
      edges.push({
        source: '0',
        target: String(lastIndex),
      });
    });

    evsesWithParent?.forEach(evse => {
      const getSource = nodes.find(el => el.data?.uri === evse.cabinetUri)?.id;
      if (!getSource) return;
      const idx = String(lastIndex + 1);
      nodes.push({
        id: idx,
        type: ENTITIES.EVSE,
        data: evse,
      });
      edges.push({
        source: String(getSource),
        target: idx,
      });
      lastIndex += 1;
    });

    return { nodes, edges };
};

export const handleFormatObjWithEvsesAndSockets = (evses, data) => {
    const nodes = JSON.parse(JSON.stringify(data?.nodes));
    const edges = JSON.parse(JSON.stringify(data?.edges));
    let lastIndex = Math.max(...nodes.map(obj => Number(obj.id)));

    evses?.forEach(evse => {
      const getSource = nodes.find(el => el.data?.uri === evse.uri)?.id;
      if (!getSource) return;
      evse?.sockets?.forEach(socket => {
        const idx = String(lastIndex + 1);
        nodes.push({
            id: idx,
            type: ENTITIES.SOCKET,
            data: {
                ...socket,
                evse,
            },
        });
        edges.push({
            source: String(getSource),
            target: idx,
        });
        lastIndex += 1;
      });
    });

    return { nodes, edges };
};

export const assignStatusToTopology = (data, statusObj) => {
    const nodes = JSON.parse(JSON.stringify(data?.nodes));
    const edges = JSON.parse(JSON.stringify(data?.edges));
    nodes?.forEach(prevObj => {
      if (prevObj.type !== ENTITIES.SOCKET) return;
      const newObj = { ...prevObj };
      newObj.data.status = statusObj[`${newObj.data?.evse?.uri}${newObj.data?.connectorId}`] || SOCKET_STATUS.UNREACHABLE;
      return newObj;
    });

    return { nodes, edges };
};

const assignCSToTree = (nodes, evseUri, activeChargingSession) => {
  // Apply cs to facility
  const facilityNode = nodes?.find(el => el.type === ENTITIES.FACILITY);
  !!facilityNode.data.activeChargingSessions
      ? facilityNode.data.activeChargingSessions.push(toReduceACS(activeChargingSession))
      : facilityNode.data.activeChargingSessions = [toReduceACS(activeChargingSession)];

  // Apply cs to evse
  const evseNode = nodes?.find(el => el.data?.uri === evseUri);
  !!evseNode.data.activeChargingSessions
      ? evseNode.data.activeChargingSessions.push(toReduceACS(activeChargingSession))
      : evseNode.data.activeChargingSessions = [toReduceACS(activeChargingSession)];

  const assignCSToCabinet = (nodes, cabinetUri) => {
    const cabinetNode = nodes?.find(el => el.data?.uri === cabinetUri);
    !!cabinetNode.data.activeChargingSessions
      ? cabinetNode.data.activeChargingSessions.push(toReduceACS(activeChargingSession))
      : cabinetNode.data.activeChargingSessions = [toReduceACS(activeChargingSession)];

    if (!!cabinetNode?.data?.parentUri) assignCSToCabinet(nodes, cabinetNode?.data?.parentUri);
  };

  // Apply cs to evse and cabinetsTree
  const cabinetUri = evseNode?.data?.cabinetUri;
  if (cabinetUri) assignCSToCabinet(nodes, cabinetUri);
};

const assignCSToTree2 = (nodes, anscestors, activeChargingSession) => {
  anscestors.forEach(anscestor => {
    const anscestorNode = nodes.find(node => node.id === anscestor.id);
    anscestorNode.data.activeChargingSessions?.push(activeChargingSession);
  });
};

const assignACSFromsocket = (activeChargingSessions, finishingChargingSessions, language, vehicles, newObj, newNodes) => {
  let activeChargingSession = activeChargingSessions?.find(
    element =>
      element.evseUri === newObj?.data?.evse?.uri &&
      element.socket === newObj?.data?.connectorId
  );

  // If finishing status, and not activeChargingSession, find last CS
  if (!activeChargingSession && isSocketFinishing(newObj?.data?.status)) {
    activeChargingSession = finishingChargingSessions?.find(
      element =>
        element.evseUri === newObj?.data?.evse?.uri &&
        element.socket === newObj?.data?.connectorId
    );
  }

  if (!activeChargingSession) {
    newObj.data.activeChargingSession = undefined;
    newNodes.push(newObj);
    return;
  }

  /////////////////// TEST ONLY ///////////////////////
  // if (activeChargingSession) activeChargingSession.power = 800;
  /////////////////////////////////////////////////////

  const vehicleInfo = getVehicleInfo(activeChargingSession, vehicles);
  newObj.data.activeChargingSession = {
    ...activeChargingSession,
    batteryLevel: activeChargingSession ? Math.floor(100 * activeChargingSession.finalSoc) : undefined,
    energy: getEnergyInKwh(activeChargingSession),
    vehicleName: vehicleInfo.name,
    vehicleType: vehicleInfo.type,
    tag: getTag(activeChargingSession),
    desiredSoc: getDesiredSoc(activeChargingSession),
    startedCharging: getStartedCharging(
      activeChargingSession,
      language.locale
    ),
    departureTime: getDepartureTime(activeChargingSession),
  };

  if (activeChargingSession) {
    assignCSToTree(newNodes, newObj.data.evse.uri, activeChargingSession);       
  }
  newNodes.push(newObj);
};

export const assignCSToTopology = (data, activeChargingSessions, finishingChargingSessions, language, vehicles) => {
  const nodes = JSON.parse(JSON.stringify(data?.nodes));
  const edges = JSON.parse(JSON.stringify(data?.edges));
  const newNodes = [];

  nodes?.forEach(prevObj => {
    const newObj = JSON.parse(JSON.stringify(prevObj));

    if (prevObj.type !== ENTITIES.SOCKET) {
      newObj.data.activeChargingSessions = [];
      newNodes.push(newObj);
      return;
    }

    assignACSFromsocket(activeChargingSessions, finishingChargingSessions, language, vehicles, newObj, newNodes);
    
  });
  
  return { nodes: newNodes, edges };
};

export const removeSocketsFromTopology = (data) => {
  if (!data) return;
  const nodes = JSON.parse(JSON.stringify(data?.nodes));
  const edges = JSON.parse(JSON.stringify(data?.edges));
  const socketsIndex = [];

  const nodesWithoutSokets = nodes?.filter(obj => {
    if (obj.type === ENTITIES.SOCKET) socketsIndex.push(obj.id);
    return obj.type !== ENTITIES.SOCKET;
  });
  const edgesWithoutSockets = edges?.filter(obj => !socketsIndex.includes(obj.target));

  return {
    nodes: nodesWithoutSokets,
    edges: edgesWithoutSockets,
  };
};

export const removeNonVisibleNodes = (data, visibleData) => {
  if (!data || !visibleData) return;
  const nodes = JSON.parse(JSON.stringify(data?.nodes));
  const edges = JSON.parse(JSON.stringify(data?.edges));

  const visibleDataNodeIndex = visibleData?.nodes?.map(node => node.id);
  const visibleDataEdgeIndex = visibleData?.edges?.map(edge => `${edge.source}${edge.target}`);

  const newNodes = nodes?.filter(node => visibleDataNodeIndex.includes(node.id));
  const newEdges = edges?.filter(edge => visibleDataEdgeIndex.includes(`${edge.source}${edge.target}`));

  return {
    nodes: newNodes,
    edges: newEdges,
  };
};

export const addSocketsToTopology = (filteredData, data) => {
  if (!data || !filteredData) return;
  const nodes = JSON.parse(JSON.stringify(filteredData?.nodes));
  const edges = JSON.parse(JSON.stringify(filteredData?.edges));
  const existingEvses = filteredData?.nodes?.filter(el => el.type === ENTITIES.EVSE);
  const existingEvsesIds = existingEvses.map(el => el.id);
  const targetSocketsEdges = data?.edges?.filter(el => existingEvsesIds.includes(el.source));
  const targetSocketsIds = targetSocketsEdges.map(el => el.target);
  const socketNodes = data?.nodes?.filter(el => targetSocketsIds.includes(el.id));
  edges.push(...targetSocketsEdges);
  nodes.push(...socketNodes);

  return {
    nodes,
    edges,
  };
};

function findPathsToTarget(edges, target) {
  const graph = {};
  edges.forEach(edge => {
    if (!graph[edge.source]) {
      graph[edge.source] = [];
    }
    graph[edge.source].push(edge.target);
  });

  const paths = [];
  const dfs = (current, path) => {
    if (current === target) {
      // En lugar de agregar el path completo, lo cortamos hasta el nodo anterior al target
      paths.push([...path].slice(0, -1));
      return;
    }

    if (graph[current]) {
      for (const neighbor of graph[current]) {
        dfs(neighbor, [...path, neighbor]);
      }
    }
  };

  dfs("0", ["0"]); 
  return paths[0];
}

function getUniqueNodes(edges) {
  const uniqueNodes = new Set(); 

  edges.forEach(edge => {
    uniqueNodes.add(edge.source);
    uniqueNodes.add(edge.target);
  });

  return Array.from(uniqueNodes);
}

export const recalculateNodes = (ids, data) => {
  const nodes = JSON.parse(JSON.stringify(data?.nodes));
  const edges = JSON.parse(JSON.stringify(data?.edges));

  const lowestId = Math.min(...ids.map(el => Number(el)));
  const pathToTarget = findPathsToTarget(edges, String(lowestId));
  const idsFromFacility = [...pathToTarget, ...ids];

  const edgesSelected = edges?.filter(obj => idsFromFacility.includes(obj.target));

  const nodesList = [];
  const nodesSelected = nodes?.filter(obj => {
    return idsFromFacility.includes(obj.id);
  });
  nodesList.push(...nodesSelected);

  // check if there are edges ids without nodes
  const uniqueNodes = getUniqueNodes(edgesSelected);
  const missingNodes = uniqueNodes.filter(id => !nodesSelected.find(el => el.id === id));
  if (missingNodes.length) {
    const nodesToAdd = nodes.filter(el => missingNodes.includes(el.id));
    nodesList.push(...nodesToAdd);
  }

  return {
    nodes: nodesList,
    edges: edgesSelected,
  };
};

export const createCompoundObj = (data) => {
    const nodes = JSON.parse(JSON.stringify(data?.nodes));
    const statuses = {
      charging: 0,
      idle: 0,
      unoccupied: 0,
      faulted: 0,
      undefined: 0,
    };
    nodes.forEach(evse => {
      switch (true) {
        case isSocketCharging(evse?.data?.status):
          statuses.charging += 1;
          break;
        case isSocketAvalible(evse?.data?.status):
          statuses.unoccupied += 1;
          break;
        case isSocketFaulted(evse?.data?.status):
          statuses.faulted += 1;
          break;
        case isSocketOffline(evse?.data?.status):
          statuses.undefined += 1;
          break;
        default:
          break;
      }
    });
  
    return [
      {
        type: CHARGER_TYPE_ALL,
        value: statuses.charging + statuses.unoccupied + statuses.faulted + statuses.undefined,
      },
      { type: SOCKET_STATUS.CHARGING, value: statuses.charging },
      { type: SOCKET_STATUS.AVAILABLE, value: statuses.unoccupied },
      { type: SOCKET_STATUS.FAULTED, value: statuses.faulted },
      { type: SOCKET_STATUS.UNREACHABLE, value: statuses.undefined },
    ];
  };
  
  export function convertDataToTreeFormat(inData, facility) {
    const getProps = (node) => {
      if (node.type === ENTITIES.FACILITY) return ({
        title: facility.name,
        key: facility.uri,
        Icon: FacilityIcon,
      });
      if (node.type === ENTITIES.SOCKET) return ({
        title: node.data.connectorId,
        key: node.data.uri,
        Icon: SocketIcon,
      });
      if (node.type === ENTITIES.EVSE) return ({
        title: node.data.name,
        key: node.data.uri,
        Icon: EvseIcon,
      });
      return ({
        title: node.data.name,
        key: node.data.uri,
        Icon: CabinetIcon,
      });
    };
    const getSaturation = (node) => {
      switch (node?.type) {
        case ENTITIES.SOCKET:
          return node?.data?.activeChargingSession?.batteryLevel;
        case ENTITIES.CABINET:
        case ENTITIES.EVSE:
        case ENTITIES.FACILITY:  
          return getBatteryLevel(node?.data?.power, node?.data?.activeChargingSessions);
        default:
          break;
      }
    };

    const nodes = inData.nodes.reduce((acc, node) => {
      const props = getProps(node);
      acc[node.id] = {
        type: node?.type,
        title: props.title,
        key: props.key,
        Icon: props.Icon,
        id: node?.id,
        meterValuespower: getPower(node?.id, inData),
        saturation: getSaturation(node),
        children: [],
        data: node?.data,
      };
      return acc;
    }, {});
  
    inData.edges.forEach(edge => {
      nodes[edge.source].children.push(nodes[edge.target]);
    });
  
    const rootNodes = Object.values(nodes).filter(node => {
      return !inData.edges.some(edge => edge.target === node.key);
    });
  
    return rootNodes;
  }

export const getLastSensorReading = (lastSensorReadings, entityUri) => {
  if (!lastSensorReadings?.length) return;
  return lastSensorReadings?.find(el => el.entityUri === entityUri);
};

export const assignSensorReadingsToTopology = (lastSensorReadings, data, graphRef) => {
  const nodes = JSON.parse(JSON.stringify(data?.nodes));

  const nodesToUpdate = [];
  nodes?.forEach(node =>  {
    if (node.type === ENTITIES.SOCKET || node.type === ENTITIES.EVSE) return;
    const nodeToUpdate = JSON.parse(JSON.stringify(node));
    const d = nodeToUpdate?.data;

    const lastSensorReading = getLastSensorReading(lastSensorReadings, d?.uri);
    if (!lastSensorReading) return;

    nodeToUpdate.data.lastSensorReading = lastSensorReading;
    nodesToUpdate.push(nodeToUpdate);
  });

  graphRef?.current?.updateNodeData(nodesToUpdate);
};

export const getParsedNode = (nodeId, graphRef) => {
  const node = graphRef?.current?.getNodeData(nodeId);
  if (!node) return;
  const parsedNode = {
    data: node?.data,
    id: node?.id,
    type: node?.type,
  };
  return parsedNode;
};

export function getIdsWithChildren(data, targetId) {
  const result = [];

  function traverse(node) {
      if (node.id === targetId) {
          result.push(node.id);
          if (node.children && node.children.length > 0) {
              traverseChildren(node.children);
          }
          return true; // Found the target
      }

      if (node.children && node.children.length > 0) {
          for (const child of node.children) {
              if (traverse(child)) {
                  return true; // Stop further traversal once target is found
              }
          }
      }
      return false;
  }

  function traverseChildren(children) {
      children.forEach((child) => {
          result.push(child.id);
          if (child.children && child.children.length > 0) {
              traverseChildren(child.children);
          }
      });
  }

  if (Array.isArray(data)) {
      for (const node of data) {
          if (traverse(node)) {
              break; // Stop if target is found
          }
      }
  }
  return result;
}

export function getIdWithParents(treeData, targetId) {
  const result = [];

  function findNodeAndParents(node, parents) {
      if (node.id === targetId) {
          result.push(targetId);
          result.push(...parents);
          return true;
      }

      if (node.children && node.children.length > 0) {
          for (const child of node.children) {
              if (findNodeAndParents(child, [node.id, ...parents])) {
                  return true;
              }
          }
      }
      return false;
  }

  if (Array.isArray(treeData)) {
      for (const node of treeData) {
          if (findNodeAndParents(node, [])) {
              break;
          }
      }
  }

  return result.reverse(); // Reverse to have the order from root to target
}