import { combineReducers } from '@reduxjs/toolkit';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import ChargingSessions from '@iso/redux/chargingSessions/reducer';
import Facilities from '@iso/redux/facilities/reducer';
import Evses from '@iso/redux/evses/reducer';
import A4i from '@iso/redux/a4i/reducer';
// import IdTags from '@iso/redux/idTags/reducer';
import Manager from '@iso/redux/manager/reducer';
import Customer from '@iso/redux/customers/reducer';
import Order from '@iso/redux/orders/reducer';
import Payments from '@iso/redux/payments/reducer';
import PaymentOptions from '@iso/redux/paymentOptions/reducer';
import Stripe from '@iso/redux/stripe/reducer';
import Wallet from '@iso/redux/wallets/reducer';
import DateRange from '@iso/redux/dateRange/reducer';
import SelectedFacilities from '@iso/redux/selectedFacilities/reducer';
import Calculator from '@iso/redux/calculator/reducer';
import Owner from '@iso/redux/owner/reducer';
import Operations from '@iso/redux/operations/reducer';
import Fleets from '@iso/redux/fleet/reducer';
import Tariffs from '@iso/redux/tariff/reducer';
import Domains from '@iso/redux/domains/reducer';
import Topology from '@iso/redux/topology/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  ChargingSessions,
  Facilities,
  Evses,
  A4i,
  // IdTags,
  Manager,
  Customer,
  Order,
  Payments,
  PaymentOptions,
  Stripe,
  Wallet,
  DateRange,
  SelectedFacilities,
  Calculator,
  Owner,
  Operations,
  Fleets,
  Tariffs,
  Domains,
  Topology,
});
