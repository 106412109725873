import React from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { defaultRoles } from '@iso/components/Auth/roleFilter';

export const DEFAULT_COMMUNICATION_PROTOCOL = 'ocpp16';
export const DEFAULT_VERSION = '0';
export const DEFAULT_MIN_CURRENT = 6;

// The order is important for sortByStatus fn
export const SOCKET_STATUS = {
  CHARGING: 'Charging',
  SUSPENDEDEV: 'SuspendedEV',
  SUSPENDEDEVSE: 'SuspendedEVSE',
  FINISHING: 'Finishing',
  FINISHED: 'Finished',
  PREPARING: 'Preparing',
  AVAILABLE: 'Available',
  CONNECTED: 'Connected',
  RESERVED: 'Reserved',
  FAULT: 'Fault',
  FAULTED: 'Faulted',
  UNREACHABLE: 'Unreachable',
  UNAVAILABLE: 'Unavailable',
  DISABLED: 'Disabled',
  UNDEFINED: 'Undefined',
  OFFLINE: 'Offline',
};

export const EVSE_STATE = {
  CHARGING: 'Charging',
  UNOCCUPIED: 'Unoccupied',
  IDLE: 'Idle',
};

export const FILTER_ICONS = {
  ORDER_GENERAL_ASC: 'ORDER_GENERAL_ASC',
  ORDER_GENERAL_DESC: 'ORDER_GENERAL_DESC',
  ORDER_NUMBER_ASC: 'ORDER_NUMBER_ASC',
  ORDER_NUMBER_DESC: 'ORDER_NUMBER_DESC',
  ORDER_CLOCK_ASC: 'ORDER_CLOCK_ASC',
  ORDER_CLOCK_DESC: 'ORDER_CLOCK_DESC',
  ORDER_LETTER_ASC: 'ORDER_LETTER_ASC',
  ORDER_LETTER_DESC: 'ORDER_LETTER_DESC',
  LETTER_A: 'LETTER_A',
  BATTERY: 'BATTERY',
  CLOCK: 'CLOCK',
  THUNDER: 'THUNDER',
  CAR: 'CAR',
  PLUG: 'PLUG',
  EARTH: 'EARTH',
  BILL: 'BILL',
  SESSIONS: 'SESSIONS',
  BATTERY_CHARGING: 'BATTERY_CHARGING',
};

export const FILTER_TYPE = {
  CHARGER_STATUS: 'chargerStatus',
  CHARGER_NAME: 'name',
  VEHICLE_ID: 'vehicleID',
  READY_FOR_DEPARTURE: 'readyForDeparture',
  MINIMUN_CHARGE_FOR_ROUTE: 'minimumChargeForRoute',
  ENERGY_DRAWN: 'energyDrawn',
  POWER: 'power',
  SOC: 'soc',
};

export const FILTER_CRITERIA = {
  ANY: 'any',
  ALL: 'all',
  INCLUDES: 'includes',
  EXACTLY: 'exactly',
  GREATER: 'greater',
  LOWER: 'lower',
};

export const EVSE_FILTER_OPTIONS = [
  {
    value: FILTER_TYPE.CHARGER_STATUS,
    label: <IntlMessages id="rt.filter.options.status" />,
    icon: FILTER_ICONS.BATTERY,
    asc: FILTER_ICONS.ORDER_GENERAL_ASC,
    desc: FILTER_ICONS.ORDER_GENERAL_DESC,
  },
  {
    value: FILTER_TYPE.CHARGER_NAME,
    label: <IntlMessages id="rt.filter.options.name" />,
    icon: FILTER_ICONS.LETTER_A,
    asc: FILTER_ICONS.ORDER_LETTER_ASC,
    desc: FILTER_ICONS.ORDER_LETTER_DESC,
  },
  {
    value: FILTER_TYPE.VEHICLE_ID,
    label: <IntlMessages id="rt.filter.options.vehicleId" />,
    icon: FILTER_ICONS.CAR,
    asc: FILTER_ICONS.ORDER_NUMBER_ASC,
    desc: FILTER_ICONS.ORDER_NUMBER_DESC,
  },
  {
    value: FILTER_TYPE.READY_FOR_DEPARTURE,
    label: <IntlMessages id="rt.filter.options.departure" />,
    icon: FILTER_ICONS.CLOCK,
    asc: FILTER_ICONS.ORDER_CLOCK_ASC,
    desc: FILTER_ICONS.ORDER_CLOCK_DESC,
  },
  // {
  //   value: FILTER_TYPE.MINIMUN_CHARGE_FOR_ROUTE,
  //   label: 'Minimum Charge for Route',
  //   icon: FILTER_ICONS.THUNDER,
  //   asc: FILTER_ICONS.ORDER_NUMBER_ASC,
  //   desc: FILTER_ICONS.ORDER_NUMBER_DESC,
  // },
  {
    value: FILTER_TYPE.ENERGY_DRAWN,
    label: <IntlMessages id="rt.filter.options.energy" />,
    icon: FILTER_ICONS.PLUG,
    asc: FILTER_ICONS.ORDER_NUMBER_ASC,
    desc: FILTER_ICONS.ORDER_NUMBER_DESC,
  },
  {
    value: FILTER_TYPE.POWER,
    label: <IntlMessages id="rt.filter.options.power" />,
    icon: FILTER_ICONS.THUNDER,
    asc: FILTER_ICONS.ORDER_NUMBER_ASC,
    desc: FILTER_ICONS.ORDER_NUMBER_DESC,
  },
  {
    value: FILTER_TYPE.SOC,
    label: <IntlMessages id="rt.filter.options.soc" />,
    icon: FILTER_ICONS.BATTERY_CHARGING,
    asc: FILTER_ICONS.ORDER_NUMBER_ASC,
    desc: FILTER_ICONS.ORDER_NUMBER_DESC,
  },
];

export const EVSE_FILTER_CRITERIA = [
  {
    value: FILTER_CRITERIA.ANY,
    label: <IntlMessages id="rt.filter.options.any" />,
  },
  {
    value: FILTER_CRITERIA.ALL,
    label: <IntlMessages id="rt.filter.options.all" />,
  },
];

export const FILTER_STRING_CRITERIA = [
  {
    value: FILTER_CRITERIA.INCLUDES,
    label: <IntlMessages id="rt.filter.options.includes" />,
  },
  {
    value: FILTER_CRITERIA.EXACTLY,
    label: <IntlMessages id="rt.filter.options.exactly" />,
  },
];

export const FILTER_NUMBER_CRITERIA = [
  {
    value: FILTER_CRITERIA.GREATER,
    label: <IntlMessages id="rt.filter.options.greater" />,
  },
  {
    value: FILTER_CRITERIA.LOWER,
    label: <IntlMessages id="rt.filter.options.lower" />,
  },
];

export const COMMANDS = {
  reset: { 
    value: 'reset', 
    text: 'Reset' ,
    roles: [defaultRoles.EVSE_COMMANDS_BASIC, defaultRoles.EVSE_COMMANDS_EXTENDED],
  },
  unlock: { 
    value: 'unlock', 
    text: 'Unlock' ,
    roles: [defaultRoles.EVSE_COMMANDS_BASIC, defaultRoles.EVSE_COMMANDS_EXTENDED],
  },
  start: {
    value: 'start',
    text: 'Start Transaction',
    roles: [defaultRoles.EVSE_COMMANDS_EXTENDED],
  },
  stop: {
    value: 'stop',
    text: 'Stop Transaction',
    roles: [defaultRoles.EVSE_COMMANDS_EXTENDED],
  },
  suspend: {
    value: 'suspend',
    text: 'Suspend',
    roles: [defaultRoles.EVSE_COMMANDS_EXTENDED],
  },
  restart: {
    value: 'restart',
    text: 'Restart',
    roles: [defaultRoles.EVSE_COMMANDS_EXTENDED],
  },
  setpoint: {
    value: 'setpoint',
    text: 'Apply Setpoint',
    roles: [defaultRoles.EVSE_COMMANDS_EXTENDED, defaultRoles.OCPI_EVSE_COMMANDS],
  },
};

export const COMMAND_RESET_TYPES = {
  hard: { value: 'hard', text: 'Hard' },
  soft: { value: 'soft', text: 'Soft' },
};

export const EVSE_CSV_COLUMS = {
  uri: { value: 'uri', text: 'Uri (only for edit)' },
  name: { value: 'name', text: 'Name *' },
  description: { value: 'description', text: 'Description' },
  voltage: { value: 'voltage', text: 'Voltage (230 | 400) *' },
  power: { value: 'power', text: 'Power (W) *' },
  manufacturer: { value: 'manufacturer', text: 'Manufacturer (name) *' },
  model: { value: 'model', text: 'Charger Model (name) *' },
  active: { value: 'active', text: 'Active (true | false) *' },
  cabinet: { value: 'cabinet', text: 'Cabinet (name)' },
  boxId: { value: 'boxId', text: 'Box id' },
};

export const SOCKET_CSV_COLUMS = {
  connectorId: { value: 'connectorId', text: 'Socket id *' },
  socketType: { value: 'socketType', text: 'Socket type (name) *' },
  electricityType: { value: 'electricityType', text: 'Socket electricity type *' },
  minCurrent: { value: 'minCurrent', text: 'Socket min current (A) *' },
  power: { value: 'power', text: 'Socket power (W) *' },
};