import { store } from "@iso/biadashboard/src/redux/store";
import { INIT_SIZE, TOPOLOGY_DIV_WIDTH, TOPOLOGY_PRESETS } from "../../containers/MainPages/Topology/constants";
import { getLocalStorageTopologyPresets } from "../../containers/MainPages/Topology/utils";

const actions = {
  SET_SELECTED_NODE: 'SET_SELECTED_NODE',
  SET_SIZE: 'SET_SIZE',
  SET_DATA: 'SET_DATA',
  CLEAR_DATA: 'CLEAR_DATA',
  SET_LOADING: 'SET_LOADING',

  setSelectedNode: (payload, isFullScreen) => {
    const getWidth = () => {
      const presets = getLocalStorageTopologyPresets() || {};
      return presets[TOPOLOGY_PRESETS.CONTENT_WIDTH] || INIT_SIZE;
    };
    const resizeSidebar = (node) => {
      let sizes;
      let width;
      let savedSizes = getWidth();
      const currentSizes = store.getState().Topology.sizes;
      if (!node) sizes = [currentSizes[0], TOPOLOGY_DIV_WIDTH - currentSizes[0], 0];
      if (node) {        
        if (savedSizes && typeof savedSizes[2] === 'number' && savedSizes[2] < 100) {
          sizes = [
            currentSizes[0],
            TOPOLOGY_DIV_WIDTH - (TOPOLOGY_DIV_WIDTH * 0.2) - currentSizes[0],
            TOPOLOGY_DIV_WIDTH * 0.2
          ];
        } else sizes = [currentSizes[0], TOPOLOGY_DIV_WIDTH - currentSizes[0] - savedSizes[2], savedSizes[2]];
        if(!isFullScreen) width = "100%";
      }
      return { sizes, width };
    };
    const values = resizeSidebar(payload?.selectedNode);

    return ({
      type: actions.SET_SELECTED_NODE,
      payload: {
        ...payload,
        sizes: values.sizes,
        width: values.width,
      },
    });
  },
  setSize: payload => ({
    type: actions.SET_SIZE,
    payload,
  }),
  setData: payload => ({
    type: actions.SET_DATA,
    payload,
  }),
  clearData: () => ({
    type: actions.CLEAR_DATA
  }),
  setLoading: payload => ({
    type: actions.SET_LOADING,
    payload,
  }),
};

export default actions;
