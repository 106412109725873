import { DEFAULT_HEIGHT, DEFAULT_WIDTH, INIT_SIZE } from '../../containers/MainPages/Topology/constants';
import actions from './actions';

const initialState = {
  selectedNode: undefined,
  sizes: INIT_SIZE,
  width: DEFAULT_WIDTH,
  height: DEFAULT_HEIGHT,
  data: undefined,
  filteredData: undefined,
  treeData: undefined,
  loading: true,
};

export default function topologyReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SELECTED_NODE:
      return {
        ...state,
        selectedNode: action.payload.selectedNode,
        sizes: action.payload.sizes || state.sizes,
        width: action.payload.width || state.width,
      };
    case actions.SET_SIZE:
      return {
        ...state,
        sizes: action.payload.sizes || state.sizes,
        width: action.payload.width || state.width,
        height: action.payload.height || state.height,
      };
    case actions.SET_DATA:
      return {
        ...state,
        data: action.payload.data || state.data,
        filteredData: action.payload.filteredData || state.filteredData,
        treeData: action.payload.treeData || state.treeData,
      };
    case actions.CLEAR_DATA:
      return initialState;
    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
