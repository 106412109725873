export const ElectricityTypes = {
  DC: { value: 'DC', text: 'DC' },
  AC_MONO: { value: 'AC single-phase', text: 'AC Single phase 230V' },
  AC_TRI: { value: 'AC three-phase', text: 'AC Three phase 400V' },
  // Temporary. Inventory and OCPP enums are different
  // Will read the values ​​below but write only the ones above
  'AC single-phase': { value: 'AC single-phase', text: 'AC Single phase 230V' },
  'AC three-phase': { value: 'AC three-phase', text: 'AC Three phase 400V' },
};

export const VoltageTypes = {
  DC: { value: 600, text: '600V' },
  AC_MONO: { value: 230, text: '230V' },
  AC_TRI: { value: 400, text: '400V' },
};

export const SocketsTypes = {
  MENNEKES: { value: 'Mennekes', text: 'Mennekes' },
  CCS: { value: 'CCS', text: 'CCS' },
  J1772: { value: 'J1772', text: 'J1772' },
  CHADEMO: { value: 'CHAdeMO', text: 'CHAdeMO' },
  TESLA: { value: 'Tesla', text: 'Tesla' },
  PANTOGRAPH: { value: 'Pantograph', text: 'Pantograph' },
  SCHUKO: { value: 'Schuko', text: 'Schuko' },
  YAZAKI: { value: 'Yazaki', text: 'Yazaki' },
};

export const CS_TABS = {
  PEAK: 'Peak.Power',
  COST: 'Cost',
  ENERGY: 'Energy',
  CO2: 'CO2',
};