const innerHeight = window?.innerHeight;
// innerHeight - header - pageHeader - bodyPadding - topBar - footer - padding
export const AVAILABLE_HEIGHT = innerHeight - 70 - 96 - 80 - 52 - 64 - 15;
export const DEFAULT_HEIGHT = AVAILABLE_HEIGHT > 0 ? AVAILABLE_HEIGHT : '60vh';
export const DEFAULT_WIDTH = '100vw';
export const TOPOLOGY_DIV_WIDTH = document?.getElementById("topology")?.offsetWidth;
export const INIT_SIZE = [
    TOPOLOGY_DIV_WIDTH ? TOPOLOGY_DIV_WIDTH * 0.2 : '20%',
    TOPOLOGY_DIV_WIDTH ? TOPOLOGY_DIV_WIDTH * 0.8 : '80%',
    0,
];

export const TOPOLOGY_PRESETS = {
    CONTENT_WIDTH: 'content_width',
    HIDE_SOCKETS: 'hide_sockets',
};

export const POWER_THRESHOLDS = [20, 80, 90, 95];
