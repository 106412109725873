import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import { fetchChargingSessions } from '@iso/redux/chargingSessions/api';
import { SOCKET_STATUS } from '@iso/constants/charger';
import { CHARGER_TYPE_ALL } from '../constants';
import { statusIsSuspended } from './index';

const NO_VALUE = '-';

export const isSocketCharging = socketsStatus => {
  return [
    SOCKET_STATUS.FINISHING,
    SOCKET_STATUS.FINISHED,
    SOCKET_STATUS.SUSPENDEDEV,
    SOCKET_STATUS.SUSPENDEDEVSE,
    SOCKET_STATUS.CHARGING,
    SOCKET_STATUS.PREPARING,
  ].includes(socketsStatus);
};

export const isSocketFinishing = socketsStatus => {
  return [SOCKET_STATUS.FINISHING, SOCKET_STATUS.FINISHED].includes(
    socketsStatus
  );
};

export const isSocketAvalible = socketsStatus => {
  return [
    SOCKET_STATUS.AVAILABLE,
    SOCKET_STATUS.CONNECTED,
    SOCKET_STATUS.RESERVED,
  ].includes(socketsStatus);
};

export const isSocketOffline = socketsStatus => {
  return [
    SOCKET_STATUS.UNREACHABLE,
    SOCKET_STATUS.UNDEFINED,
    SOCKET_STATUS.UNAVAILABLE,
    undefined,
  ].includes(socketsStatus);
};

export const isSocketFaulted = socketsStatus => {
  return [
    SOCKET_STATUS.FAULT,
    SOCKET_STATUS.FAULTED,
    SOCKET_STATUS.DISABLED,
  ].includes(socketsStatus);
};

export const getBatteryLevel = activeChargingSession => {
  if (activeChargingSession) {
    return Math.floor(100 * activeChargingSession.finalSoc);
  }
};

export const getPower = activeChargingSession => {
  if (activeChargingSession) {
    return Number((activeChargingSession.power / 1000)?.toFixed(1));
  }
};

export const dontShowPower = (socketsStatus, power) => {
  return statusIsSuspended(socketsStatus) || !power;
};

export const getEnergyInKwh = activeChargingSession => {
  if (activeChargingSession) {
    return Number(activeChargingSession.totalEnergyKwh?.toFixed(1));
  }
};

export const getTag = activeChargingSession => {
  if (activeChargingSession) {
    return activeChargingSession.userTag;
  }
  return NO_VALUE;
};

export const getVehicleInfo = (activeChargingSession, vehicles) => {
  if (activeChargingSession?.evUri) {
    const vehicle = vehicles?.find(v => v.uri == activeChargingSession.evUri);
    return vehicle ? 
      { name: vehicle.name, type: vehicle.evType } : 
      { name: NO_VALUE };
  }
  return { name: NO_VALUE };
};

export const getDesiredSoc = activeChargingSession => {
  if (activeChargingSession?.desiredSoc) {
    return Number((activeChargingSession.desiredSoc * 100)?.toFixed(1));
  }
  return 100;
};

export const getStartedCharging = (activeChargingSession, locale) => {
  if (activeChargingSession?.connectionTime) {
    return dayjs(activeChargingSession.connectionTime)
      .locale(locale)
      .fromNow();
  }
  return NO_VALUE;
};

export const getDepartureTime = activeChargingSession => {
  if (!activeChargingSession?.estimatedSessionDuration) return;
  return dayjs(activeChargingSession.connectionTime).add(
    activeChargingSession.estimatedSessionDuration,
    'seconds'
  );
};

const getChargingSessions = async chargerUri => {
  try {
    const chargingSessions = await fetchChargingSessions(
      chargerUri,
      dayjs().subtract(1, 'days')?.toISOString(),
      dayjs()?.toISOString()
    );
    return chargingSessions;
  } catch (error) {
    console.error(error);
  }
};

const getLastChargingSession = async (chargerUri, connectorId) => {
  const chargingSessions = await getChargingSessions(chargerUri);
  const chargingSessionsBySocket = chargingSessions.filter(
    el => !!el.disconnectionTime && el.socket === connectorId && dayjs(el.disconnectionTime).isAfter(dayjs().subtract(3, 'hours'))
  );
  const lastCS = chargingSessionsBySocket?.length
    ? chargingSessionsBySocket.reduce((a, b) =>
        dayjs(a.disconnectionTime).isBefore(dayjs(b.disconnectionTime)) ? b : a
      )
    : {};
  return lastCS;
};

export const findAllFinishingStatusCS = async socketsArray => {
  const promiseArray = [];
  socketsArray.forEach(socket => {
    if (isSocketFinishing(socket.status)) {
      promiseArray.push(
        getLastChargingSession(socket.charger?.uri, socket.connectorId)
      );
    }
  });
  const result = await Promise.all(promiseArray);
  return result;
};

export const createCompoundObj = (evses) => {
  const statuses = {
    charging: 0,
    idle: 0,
    unoccupied: 0,
    faulted: 0,
    undefined: 0,
  };
  evses.forEach(evse => {
    switch (true) {
      case isSocketCharging(evse.status):
        statuses.charging += 1;
        break;
      case isSocketAvalible(evse.status):
        statuses.unoccupied += 1;
        break;
      case isSocketFaulted(evse.status):
        statuses.faulted += 1;
        break;
      case isSocketOffline(evse.status):
        statuses.undefined += 1;
        break;
      default:
        break;
    }
  });

  return [
    {
      type: CHARGER_TYPE_ALL,
      value: statuses.charging + statuses.unoccupied + statuses.faulted + statuses.undefined,
    },
    { type: SOCKET_STATUS.CHARGING, value: statuses.charging },
    { type: SOCKET_STATUS.AVAILABLE, value: statuses.unoccupied },
    { type: SOCKET_STATUS.FAULTED, value: statuses.faulted },
    { type: SOCKET_STATUS.UNREACHABLE, value: statuses.undefined },
  ];
};